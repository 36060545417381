import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

import { FacturasService } from '../services/facturas.service';
import { FacturaDialog } from '../facturas/factura.dialog';
import {Tool} from '../shared/tool';

@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.css']
})
export class FacturasComponent implements OnInit {

  public idCuenta:string;
  public cliente:any={};
  public Usos:any[]=[
      {clave:"G01",	descripcion:"Adquisición de mercancías"},
      {clave:"G02",	descripcion:"Devoluciones, descuentos o bonificaciones"},
      {clave:"G03",	descripcion:"Gastos en general"},
      {clave:"I01",	descripcion:"Construcciones"},
      {clave:"I02",	descripcion:"Mobiliario y equipo de oficina por inversiones"},
      {clave:"I03",	descripcion:"Equipo de transporte"},
      {clave:"I04",	descripcion:"Equipo de computo y accesorios"},
      {clave:"I05",	descripcion:"Dados, troqueles, moldes, matrices y herramental"},
      {clave:"I06",	descripcion:"Comunicaciones telefónicas"},
      {clave:"I07",	descripcion:"Comunicaciones satelitales"},
      {clave:"I08",	descripcion:"Otra maquinaria y equipo"},
      {clave:"D01",	descripcion:"Honorarios médicos, dentales y gastos hospitalarios"},
      {clave:"D02",	descripcion:"Gastos médicos por incapacidad o discapacidad"},
      {clave:"D03",	descripcion:"Gastos funerales"},
      {clave:"D04",	descripcion:"Donativos"},
      {clave:"D05",	descripcion:"Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)"},
      {clave:"D06",	descripcion:"Aportaciones voluntarias al SAR"},
      {clave:"D07",	descripcion:"Primas por seguros de gastos médicos"},
      {clave:"D08",	descripcion:"Gastos de transportación escolar obligatoria"},
      {clave:"D09",	descripcion:"Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones"},
      {clave:"D10",	descripcion:"Pagos por servicios educativos (colegiaturas)"},
      {clave:"P01",	descripcion:"Por definir"}
    ];
  public RegimenesFiscales:any[]=[
      {clave:601,regimenFiscal:'General de Ley Personas Morales'},
      {clave:603,regimenFiscal:'Personas Morales con Fines no Lucrativos'},
      {clave:605,regimenFiscal:'Sueldos y Salarios e Ingresos Asimilados a Salarios'},
      {clave:606,regimenFiscal:'Arrendamiento'},
      {clave:607,regimenFiscal:'Régimen de Enajenación o Adquisición de Bienes'},
      {clave:608,regimenFiscal:'Demás ingresos'},
      {clave:610,regimenFiscal:'Residentes en el Extranjero sin Establecimiento Permanente en México'},
      {clave:611,regimenFiscal:'Ingresos por Dividendos (socios y accionistas)'},
      {clave:612,regimenFiscal:'Personas Físicas con Actividades Empresariales y Profesionales'},
      {clave:614,regimenFiscal:'Ingresos por intereses'},
      {clave:615,regimenFiscal:'Régimen de los ingresos por obtención de premios'},
      {clave:616,regimenFiscal:'Sin obligaciones fiscales'},
      {clave:620,regimenFiscal:'Sociedades Cooperativas de Producción que optan por diferir sus ingresos'},
      {clave:621,regimenFiscal:'Incorporación Fiscal'},
      {clave:622,regimenFiscal:'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'},
      {clave:623,regimenFiscal:'Opcional para Grupos de Sociedades'},
      {clave:624,regimenFiscal:'Coordinados'},
      {clave:625,regimenFiscal:'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'},
      {clave:626,regimenFiscal:'Régimen Simplificado de Confianza'}
    ];
  
  constructor(
    private router: Router, 
    private tool: Tool,
    public dialog: MatDialog,
    public objFactura: FacturasService
  ) {
    if(this.router.url.indexOf('#')>0){
      this.idCuenta=this.router.url.substr(this.router.url.indexOf('#')+1,this.router.url.length);
      this.objFactura.listar(this.idCuenta);
    }
  }

  ngOnInit(): void {
  }

  displayFn(uso):any{
    console.log(uso);
    return((uso&&uso.descripcion)?uso.descripcion:uso);
  }

  displayFnRegimen(regimenFiscal):any{
    console.log(regimenFiscal);
    return((regimenFiscal&&regimenFiscal.regimenFiscal)?regimenFiscal.regimenFiscal:regimenFiscal);
  }

  buscarCliente(RFC){
    this.objFactura.buscarCliente(RFC,this.RegimenesFiscales);
  }

  copiaCliente(cliente){
    this.cliente=JSON.parse(JSON.stringify(cliente));
  }

  buscarTicket(idCuenta,numero,referencia){
    if(this.objFactura.cuentas.find(cuenta=>cuenta.numero==numero.value)){
      this.tool.msgBox("Aviso", "El ticket ya se encuentra en la lista.",this.dialog);
    }else{
      this.objFactura.buscarTicket(idCuenta,numero.value,referencia.value)
        .subscribe(
          (data:any) => {
              if(data){
                  if(data.cuenta.length>0){
                    this.objFactura.cuenta=data.cuenta[0];
                    this.objFactura.cuenta.productos=data.productos;
                    this.objFactura.cuenta.anexos=data.anexos[0];
                    this.objFactura.cuenta.pagos=data.pagos;
                    if(this.objFactura.cuenta.estado<2){
                      this.tool.msgBox("Aviso", "La cuanta no fue pagada.",this.dialog);
                    }else if(this.objFactura.cuenta.MesFactura!=this.objFactura.cuenta.MesActual){
                      this.tool.msgBox("Aviso", "El ticket no corresponde al mes corriente.",this.dialog);
                    }else if(this.objFactura.cuenta.idFactura){
                      this.tool.msgBox("Aviso", "El ticket ya fué facturado.",this.dialog);
                    }else{
                      console.log(this.objFactura.cuenta);
                      let dialogRef = this.dialog.open(FacturaDialog);
                    }
                  }else{
                      this.tool.msgBox("Aviso", "El ticket no fué encontrado.",this.dialog);
                  }
              }else{
                  this.tool.msgBox("Error", data.Error,this.dialog);
              }
          },
          error=>{
              this.tool.manejoError(error);
              this.tool.hide();
          },
          ()=>this.tool.hide()
        );
        numero.value="";
        referencia.value="";
    }
  }

  copiaProducto(event):void{
    if(event.isUserInput){
      console.log(event.source.value);
      let producto=event.source.value;
    }else if(!event.source){
      console.log(event);
      let producto=event;
    }
  }

  eliminar(i){
    this.objFactura.cuentas.splice(i,1);
  }

  facturar(){
    this.objFactura.factura.cliente=this.objFactura.cliente;
    this.objFactura.factura.cuentas=[];
    this.objFactura.factura.idCuenta=this.idCuenta;
    this.objFactura.cuentas.forEach(cuenta=>{
      this.objFactura.factura.cuentas.push({idCuenta:cuenta.idCuenta, numero:cuenta.numero});
    });

    this.objFactura.facturar()
      .subscribe(
        (data:any) => {
            if(!data.Error){//console.log(data);
              this.objFactura.datosFactura=data;
            }else{
                //this.datosFactura.correo=this.cliente.correo;
                //this.datosFactura.error=data.Error;
                this.objFactura.cuentas=[];
                this.objFactura.datosFactura={};
                this.cliente={};
                this.tool.msgBox("Error", data.Error,this.dialog);
            }
        },
        error=>{
            this.tool.manejoError(error);
            this.tool.hide();
        },
        ()=>this.tool.hide()
    );
  }

  eliminarDiacriticosEs(texto) {
      return texto
            .normalize('NFD')
            .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
            .normalize().toUpperCase();
  }

}
