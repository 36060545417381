import { Injectable }    from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import {MessageDialog, ConfirmDialog, LoaderDialog} from './dialogs.component';

import { Router } from '@angular/router';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';

declare let AndroidInterface;
export class HeaderTable{
    'Content-Type': string;
    idSession: string;
}

@Injectable({
    providedIn: 'root'
})
export class Tool{
    
    public contador=0;
    public headers:HttpHeaders;
    public loaderDialogRef; 

    constructor(
        private router: Router, 
        public dialog: MatDialog 
    ){
        this.headers= new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json;');
        this.headers =this.headers.set('idSession','OK');
    }

    Url():string{
        if(window.location.hostname=="localhost"){
            return"http://localhost:8082";
        }else if(window.location.hostname.indexOf("192.168")>=0){
            return"http://"+window.location.hostname+":8082";
        }else{
            return "./public/index.php";
        }
    }

    options(){
        if(this.headers.get('idSession')=="OK" && localStorage.getItem("idSession")){
            this.headers=this.headers.set('idSession',String(localStorage.getItem("idSession")));
        }
        this.httpOptions={
            headers:this.headers
        }
        console.log("idSession",this.headers.get('idSession'));
        return this.httpOptions;
    }

    httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    show():void{
        setTimeout(()=>{
            this.contador++;
            if(!this.loaderDialogRef){
                this.loaderDialogRef = this.dialog.open(LoaderDialog, {
                    height: '300px',
                    width: '300px',
                    disableClose: true 
                  });
            }
       },0);
    }

    hide():void{
        setTimeout(()=>{
            if(this.contador>1){
                this.contador--;
            }else{
                this.contador--;
                if(this.loaderDialogRef){
                    this.loaderDialogRef.close();
                    this.loaderDialogRef=undefined;
                }
            }
       },100);
    }

    msgBox(titulo, texto, dialog):void{
        let dialogRef = dialog.open(MessageDialog);
        dialogRef.componentInstance.titulo = titulo;
        dialogRef.componentInstance.texto = texto;
        return dialogRef.afterClosed();
    }
    
    confirm(titulo, texto, dialog): Promise<boolean>{
        let dialogRef = dialog.open(ConfirmDialog);
        dialogRef.componentInstance.titulo = titulo;
        dialogRef.componentInstance.texto = texto;
        return dialogRef.afterClosed().toPromise().then(result => result).catch(false);
    }

    manejoError(error):void{
        switch(error.status){
            case 0:
                this.msgBox("Sin Internet", "Verifica la conexión a Internet.", this.dialog);
                break;
            case 401:
                this.router.navigate(['/Login']);
                this.msgBox("Error", "No tiene permiso para ésta acción, puede que haya iniciado sesión en otro equipo.", this.dialog);
                break;
            case 504:
                this.msgBox("Internet Inestable", "La conexión a internet se encuentra inestable, pruebe desconectar su moden y conectarlo nuevamente. Si el problema no se soluciona; comuníquese con su proveedor de Internet.", this.dialog);
                break;
            default:
                this.msgBox("Error", JSON.stringify(error), this.dialog);
        }
    }

}